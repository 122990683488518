<template lang="pug">
  div
    Loading(v-if="isLoading")
    .container-fluid.wrapper(v-if="!isLoading")
      #prefectures
        //- 両側にそれぞれ2colのoffsetを入れる
        .col-lg-8.offset-lg-2
          table#japan-map
            tr
              td(colspan="22" rowspan="4")  
              td.pref(colspan="3" v-bind:class="{blink:isActive_01}") 
                a#hokkaido 北海道
            tr
              td(colspan="3")  
            tr
              td.pref(colspan="3" v-bind:class="{blink:isActive_02}")
                a#aomori  青森県
            tr
              td.pref(v-bind:class="{blink:isActive_05}")
                a#akita  秋田県
              td.pref(colspan="2" v-bind:class="{blink:isActive_03}")
                a#iwatex  岩手県
            tr
              td(colspan="15" rowspan="2")  
              td.pref(colspan="2" rowspan="2" v-bind:class="{blink:isActive_17}") 
                a#ishikawa 石川県
              td(colspan="5")  
              td.pref(v-bind:class="{blink:isActive_06}")
                a#yamagata  山形県
              td.pref(colspan="2" v-bind:class="{blink:isActive_04}") 
                a#miyagi 宮城県
            tr
              td.pref(colspan="3" v-bind:class="{blink:isActive_16}")
                a#toyama  富山県
              td.pref(colspan="3" v-bind:class="{blink:isActive_15}")
                a#niigata  新潟県
              td.pref(colspan="2" v-bind:class="{blink:isActive_07}")
                a#fukushima  福島県
            tr
              td(colspan="13")  
              td.pref(colspan="4" v-bind:class="{blink:isActive_18}")
                a#fukui  福井県
              td.pref(rowspan="2" v-bind:class="{blink:isActive_21}") 
                a#gifu 岐阜県
              td.pref(colspan="2" rowspan="2" v-bind:class="{blink:isActive_20}")
                a#nagano  長野県
              td.pref(colspan="2" v-bind:class="{blink:isActive_10}")
                a#gunma  群馬県
              td.pref(v-bind:class="{blink:isActive_09}") 
                a#tochigi 栃木県
              td.pref(colspan="2" rowspan="2" v-bind:class="{blink:isActive_08}") 
                a#ibaraki 茨城県
            tr
              td(colspan="5")  
              td.pref(v-bind:class="{blink:isActive_35}")
                a#yamaguchi 山口県
              td.pref(colspan="2" v-bind:class="{blink:isActive_32}")
                a#shimane 島根県
              td.pref(colspan="2" v-bind:class="{blink:isActive_31}")
                a#tottori  鳥取県
              td.pref(rowspan="2" v-bind:class="{blink:isActive_28}") 
                a#hyogo 兵庫県
              td.pref(colspan="2" v-bind:class="{blink:isActive_26}")
                a#kyoto  京都府
              td.pref(colspan="4" v-bind:class="{blink:isActive_25}")
                a#shiga  滋賀県
              td.pref(colspan="2" v-bind:class="{blink:isActive_19}")
                a#yamanashi  山梨県
              td.pref(v-bind:class="{blink:isActive_11}")
                a#saitama  埼玉県
            tr
              td.pref(rowspan="2" v-bind:class="{blink:isActive_42}")
                a#nagasaki  長崎県
              td.pref(rowspan="2" v-bind:class="{blink:isActive_41}")
                a#saga  佐賀県
              td.pref(colspan="3" v-bind:class="{blink:isActive_40}")
                a#fukuoka  福岡県
              td  
              td.pref(colspan="2" v-bind:class="{blink:isActive_34}")
                a#hiroshima  広島県
              td.pref(colspan="2" v-bind:class="{blink:isActive_33}")
                a#okayama  岡山県
              td.pref(colspan="2" v-bind:class="{blink:isActive_27}")
                a#osaka  大阪府
              td.pref(colspan="2" v-bind:class="{blink:isActive_29}")
                a#nara  奈良県
              td.pref(colspan="2" rowspan="2" v-bind:class="{blink:isActive_24}")
                a#mie  三重県
              td.pref(v-bind:class="{blink:isActive_23}")
                a#aichi  愛知県
              td.pref(colspan="2" rowspan="2" v-bind:class="{blink:isActive_22}")
                a#shizuoka  静岡県
              td.pref(colspan="2" rowspan="2" v-bind:class="{blink:isActive_14}") 
                a#kanagawa 神奈川県
              td.pref(v-bind:class="{blink:isActive_13}")
                a#tokyo  東京都
              td.pref(colspan="2" rowspan="2" v-bind:class="{blink:isActive_12}")
                a#chiba  千葉県
            tr
              td.pref(rowspan="3" v-bind:class="{blink:isActive_43}")
                a#kumamoto  熊本県
              td.pref(colspan="2" v-bind:class="{blink:isActive_44}")
                a#oita  大分県
              td(colspan="6")  
              td.pref(colspan="4" v-bind:class="{blink:isActive_30}")
                a#wakayama  和歌山県
              td  
              td  
            tr
              td(colspan="2" rowspan="3")  
              td.pref(colspan="2" rowspan="2" v-bind:class="{blink:isActive_45}")
                a#miyazaki  宮崎県
              td(rowspan="2")  
              td.pref(colspan="2" v-bind:class="{blink:isActive_38}")
                a#ehime  愛媛県
              td.pref(colspan="2" v-bind:class="{blink:isActive_37}")
                a#kagawa  香川県
              td(colspan="15" rowspan="4")  
            tr
              td.pref(colspan="2" v-bind:class="{blink:isActive_38}")
                a#kochi  高知県
              td.pref(colspan="2" v-bind:class="{blink:isActive_36}") 
                a#tokushima 徳島県
            tr
              td.pref(colspan="3" v-bind:class="{blink:isActive_46}")
                a#kagoshima  鹿児島県
              td(colspan="5")  
            tr
              td.pref(colspan="2" v-bind:class="{blink:isActive_47}")
                a#okinawa  沖縄県
              td(colspan="8")  
</template>

<script>
import Loading from '@/components/Loading.vue'

export default {
  // データオブジェクト
  data() {
    return {
      // ログイン済みかどうか
      isLogin: this.$user.isLogin,
      prefecturesList:this.$parent.prefecturesList,
      isActive_01: false,
      isActive_02: false,
      isActive_03: false,
      isActive_04: false,
      isActive_05: false,
      isActive_06: false,
      isActive_07: false,
      isActive_08: false,
      isActive_09: false,
      isActive_10: false,
      isActive_11: false,
      isActive_12: false,
      isActive_13: false,
      isActive_14: false,
      isActive_15: false,
      isActive_16: false,
      isActive_17: false,
      isActive_18: false,
      isActive_19: false,
      isActive_20: false,
      isActive_21: false,
      isActive_22: false,
      isActive_23: false,
      isActive_24: false,
      isActive_25: false,
      isActive_26: false,
      isActive_27: false,
      isActive_28: false,
      isActive_29: false,
      isActive_30: false,
      isActive_31: false,
      isActive_32: false,
      isActive_33: false,
      isActive_34: false,
      isActive_35: false,
      isActive_36: false,
      isActive_37: false,
      isActive_38: false,
      isActive_39: false,
      isActive_40: false,
      isActive_41: false,
      isActive_42: false,
      isActive_43: false,
      isActive_44: false,
      isActive_45: false,
      isActive_46: false,
      isActive_47: false,
      isLoading:true
    }
  },
  watch: {
    '$parent.prefecturesList' (to, from) {
      this.prefecturesList = this.$parent.prefecturesList
      this.getPrefecturesList()
    }
  },  
  created() {
    this.createdProcess()
  },
  // メソッド
  methods: {
    createdProcess(){
      this.getPrefecturesList()    
      this.isLoading = false
  },
    async getPrefecturesList(){
      // 配列に格納されている都道府県のblinkクラスをアクティブにする
      for (let key in this.prefecturesList) {
        //件数0の場合は対象外
        if(this.prefecturesList[key] == 0){
          continue
        }
        //TODO:evalが使えないのでやむを得ず全件分switchしている。
        switch(key) {
          case "1":// 1：北海道
            this.isActive_01 = true
            break
          case "2":// 2：青森県
            this.isActive_02 = true
            break
          case "3":// 3：岩手県
            this.isActive_03 = true
            break
          case "4":// 4：宮城県
            this.isActive_04 = true
            break
          case "5":// 5：秋田県
            this.isActive_05 = true
            break
          case "6":// 6：山形県
            this.isActive_06 = true
            break
          case "7":// 7：福島県
            this.isActive_07 = true
            break
          case "8":// 8：茨城県
            this.isActive_08 = true
            break
          case "9":// 9：栃木県
            this.isActive_09 = true
            break
          case "10":// 10：群馬県
            this.isActive_10 = true
            break
          case "11":// 11：埼玉県
            this.isActive_11 = true
            break
          case "12":// 12：千葉県
            this.isActive_12 = true
            break
          case "13":// 13：東京都
            this.isActive_13 = true
            break
          case "14":// 14：神奈川県
            this.isActive_14 = true
            break
          case "15":// 15：新潟県
            this.isActive_15 = true
            break
          case "16":// 16：富山県
            this.isActive_16 = true
            break
          case "17":// 17：石川県
            this.isActive_17 = true
            break
          case "18":// 18：福井県
            this.isActive_18 = true
            break
          case "19":// 19：山梨県
            this.isActive_19 = true
            break
          case "20":// 20：長野県
            this.isActive_20 = true
            break
          case "21":// 21：岐阜県
            this.isActive_21 = true
            break
          case "22":// 22：静岡県
            this.isActive_22 = true
            break
          case "23":// 23：愛知県
            this.isActive_23 = true
            break
          case "24":// 24：三重県
            this.isActive_24 = true
            break
          case "25":// 25：滋賀県
            this.isActive_25 = true
            break
          case "26":// 26：京都府
            this.isActive_26 = true
            break
          case "27":// 27：大阪府
            this.isActive_27 = true
            break
          case "28":// 28：兵庫県
            this.isActive_28 = true
            break
          case "29":// 29：奈良県
            this.isActive_29 = true
            break
          case "30":// 30：和歌山県
            this.isActive_30 = true
            break
          case "31":// 31：鳥取県
            this.isActive_31 = true
            break
          case "32":// 32：島根県
            this.isActive_32 = true
            break
          case "33":// 33：岡山県
            this.isActive_33 = true
            break
          case "34":// 34：広島県
            this.isActive_34 = true
            break
          case "35":// 35：山口県
            this.isActive_35 = true
            break
          case "36":// 36：徳島県
            this.isActive_36 = true
            break
          case "37":// 37：香川県
            this.isActive_37 = true
            break
          case "38":// 38：愛媛県
            this.isActive_38 = true
            break
          case "39":// 39：高知県
            this.isActive_39 = true
            break
          case "40":// 40：福岡県
            this.isActive_40 = true
            break
          case "41":// 41：佐賀県
            this.isActive_41 = true
            break
          case "42":// 42：長崎県
            this.isActive_42 = true
            break
          case "43":// 43：熊本県
            this.isActive_43 = true
            break
          case "44":// 44：大分県
            this.isActive_44 = true
            break
          case "45":// 45：宮崎県
            this.isActive_45 = true
            break
          case "46":// 46：鹿児島県
            this.isActive_46 = true
            break
          case "47":// 47：沖縄県
            this.isActive_47 = true
            break
          default:
            //doNothing
            break
        }        
      }
      this.isLoading = false

    },
    asyncDialog(msg) {
      return new Promise((resolve) => {
        this.$bvModal.msgBoxOk(msg, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
        .then(value => {
          resolve(value)
        })
        .catch(err => {
          // An error occurred
        })
      })
    }
  },
  // 算出プロパティ
  computed: {
  },
  // コンポーネント
  components: {
    Loading
  }
}
</script>

<style lang="stylus" scoped>
.wrapper
  padding 1.5rem 0px
  background #eceeef
#japan-map 
  width 100%
  border-collapse separate
  border-spacing 1px
  margin 0 0px 2rem 0px
.pref 
  background #224163
  min-height 10px
  font-size 0.7em
  text-align center
  padding 3px
  border-radius 5px
  a 
    display block
    min-height 10px
    color #dee1e3
    display block
    min-height 20px
    &:hover
      color #dee1e3

#hokkaido 
  margin 20px 0

.blink
  background #ca9802
  //animation blinkAnime 5.5s infinite alternate

@keyframes blinkAnime{
   0% { background : #ffed00 }
  50% { background: #d1c200 }
  100% { background: #fff89e }
}

</style>
